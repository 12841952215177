
<template>
    <CRow class="mt-3">
        <CCol col="12" xl="12" >
            <CCol sm="12" >
                <CRow>
                    <CCol sm="12" class="d-flex align-items-center justify-content-end">
                        <CButton
                            color="add"
                            @click="activarModal()"
                            v-c-tooltip="{
                                content: $t('label.group'),
                                placement: 'top-end'
                            }"
                        >
                            <CIcon name="cil-playlist-add"/>&nbsp;{{$t('label.nuevo')}}
                        </CButton>
                        <CButton
                            color="excel"
                            style="margin-left:5px;"
                            @click="generarXLS(true)"
                            v-c-tooltip="{
                                content: $t('label.exportToExcel'),
                                placement: 'top-end'
                            }"
                        >
                            <CIcon name="cil-file-excel"/>&nbsp; XLSX
                        </CButton>
                    </CCol>
                </CRow>
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    pagination
                    :loading="loading"
                     
                >
                    <template #loading>
                        <loading/>
                    </template>
                    <template #TpCargoId="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Route="{item}">
                        <td class="center-cell">
                            <img
                                :src="srcImage(item.Route)"
                                :alt="item.TpGrupoId"
                                width="50px"
                                height="50px"
                                class="img-thumbnail"
                                @error="setAltImg"
                            />
                        </td>
                    </template>
                    <template #Status="{item}">
                        <td class="text-center">
                            <CBadge :color="getBadge(item.Status)">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                    <template #acciones="{item, index}">
                        <td class="text-center">
                                <CButton
                                    square
                                    color="watch"
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.codesPerDocuments'),
                                        placement: 'top-end'
                                    }"
                                    @click="viewCode(item)"
                                >
                                    <CIcon name="eye"/>
                                </CButton>
                                <CButton
                                    color="edit"
                                    square
                                    size="sm"
                                    v-c-tooltip="{
                                        content: $t('label.edit')+' '+$t('label.containerGroup'),
                                        placement: 'top-end'
                                    }"
                                    @click="updateModal(item, index)"
                                >
                                    <CIcon name="pencil"/>
                                </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CCol>
    </CRow>
</template>
<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
    .pcss3t > ul > li {
	    min-height: 370px;
    }
</style>
<script>
    import ENUM from '@/_store/enum';
    import { DateFormater } from '@/_helpers/funciones';
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';
    import Reportes from '@/_mixins/reportes';

    function fields(){
        return [
            { key: 'TpCargoId',label: '#', filter:false, _style: 'width:3%; text-align:center',},
            { key: 'TpCargoName',label: this.$t('label.group'), _style:'width:23%;' },
            { key: 'TpCargoCode',label: this.$t('label.typecargocode'), _style: 'width:14%;' },
            { key: 'Route',label: this.$t('label.image'), _style: 'width: 10%; text-align:center;',filter: false,sorter: false,},
            { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width:14;'},
            { key: 'FormatedDate', label:this.$t('label.date'), _style: 'width: 14%; ', _classes: 'text-center'},
            { key: 'Status',label: this.$t('label.status'), _style:'width:14%; text-align:center;' },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'min-width: 80px; width:8%', 
                sorter: false, 
                filter: false
            }
        ];
    }

    //data
    function data() {
        return {
            srcError:'/img/errorimage.jpg',
            listTable:[],
            activePage: 1,
        }
    }

    //methods
    function activarModal(){
        this.$store.state.contenedores.groupRoute = '';
        this.$store.state.contenedores.modalCargos = true;
      
    }
    function viewCode(item){
       
        this.$store.state.contenedores.MetadataJson = item.MetadataJson;
        this.$store.state.contenedores.modalCode = true;
        this.$store.state.contenedores.modalName = item.TpCargoName;
    }
    function updateModal(item){
        this.$store.state.contenedores.groupRoute = item.Route;
        this.$store.state.contenedores.modalCargos = true;
        this.$store.commit('contenedores/asignarid', item.TpCargoId);
    }
    function srcImage(icon){
        if(icon === null || icon === '' || icon === undefined){
            return this.srcError;
        }else{
            return icon;
        }
    }
    function setAltImg(event){
        event.target.src = this.srcError;
    }
    async function generarXLS(valor){ 
        if(this.computedItems.length !== 0){
            this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
            let itemsArray = [];
            await this.computedItems.map(async(item,index) => {
                let objeto = {};
                objeto['#'] = index+1;
                objeto[this.$t('label.group')] = item.TpCargoName;
                objeto[this.$t('label.acronym')] = item.TpCargoCode;
                objeto[this.$t('label.accesories')] = item.FgAccesory ? this.$t('label.apply') : 'N/A';
                objeto[this.$t('label.description')] = item.TpCargoDs;
                objeto[this.$t('label.user')] = item.UltimoUsuario;
                objeto[this.$t('label.date')] = item.FormatedDate;
                itemsArray.push(objeto);
            });
            let nombreLibro = this.$i18n.locale == 'es' ? 'GrupoDeContenedores' : 'ContainerCroup';

            await this.getExcelArray(itemsArray, this.$t('label.containerLists.groupsLists'), valor, '', false, nombreLibro);
            this.$store.state.contenedores.apiStateForm = ENUM.INIT;
        }
    }

    //computed
    function computedItems () {
        return this.$store.getters["contenedores/myDataGrupotable"];
    }

    function formatedItems() {    
        return this.computedItems.filter(item => {
            return item
        })
    }

    //watch
    function tabIndex(newValue,OldValue){
        if(newValue === 0){
            this.$store.dispatch('contenedores/getGrupolist');
        }
    }
    
    export default {
        name: 'index-groups',
        data,
        mixins:[GeneralMixin,Reportes],
        props: ['loading'],    
        methods: {
            activarModal,
            updateModal,
            viewCode,
            srcImage,
            generarXLS,
            setAltImg
        },
        computed: {
            computedItems,
            formatedItems,
            fields,
            ...mapState({
                tabIndex: state => state.contenedores.tabIndex,
            })
        },
        watch:{
            tabIndex
        }
    }   
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>